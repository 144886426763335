import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import Section from "../../components/Section"
import TestimonialCard2 from "../../components/cards/TestimonialCard2"
import SectionTitle from "../../components/SectionTitle"
import Container from "../../components/Container"
import Button from "../../components/Button"
import SectionText from "../../components/SectionText"

const Testimonial2 = ({ className }) => {
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: {
      perView: 1,
      spacing: 52,
    },
  })

  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query {
      image1: allFile(filter: { relativePath: { eq: "testimonial-1.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image2: allFile(filter: { relativePath: { eq: "testimonial-2.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image3: allFile(filter: { relativePath: { eq: "testimonial-3.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image4: allFile(filter: { relativePath: { eq: "testimonial-4.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <Section className={className}>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-8 items-start justify-start laptop:justify-center laptop:items-center tablet:gap-14 laptop:gap-[82px]">
            <div
              data-aos="fade-in"
              className="flex flex-col items-start justify-start laptop:text-center laptop:items-center"
            >
              <SectionTitle className="">
                Our customers{" "}
                <span className="text text-primary-600">love </span>
                us
              </SectionTitle>
              <SectionText className=" !pb-0 max-w-[520px] mx-auto">
                {" "}
                Energistically myocardinate clicks-and-mortar testing procedures
                whereas next-generation manufactured products.
              </SectionText>
            </div>
            <div className="relative block w-full pt-16">
              <div className="absolute left-0 flex gap-4 translate-y-full laptop:right-0 laptop:left-auto bottom-full ">
                <Button
                  className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
                  iconOnly={"false"}
                  icon={<ChevronLeft className="font-bold text-body-xl" />}
                  onClick={e =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                ></Button>
                <Button
                  className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10"
                  iconOnly={"false"}
                  icon={<ChevronRight className="font-bold text-body-xl" />}
                  onClick={e =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                ></Button>
              </div>
            </div>
          </div>
          <div ref={sliderRef} className="keen-slider">
            <TestimonialCard2
              imageData={image1.nodes[0]}
              className=""
              personName="Erin E. Behrens"
              personInfo="Frontend Developer, ABC Tech"
            >
              <p>
                Distinctively exploit optimal alignments for intuitive
                bandwidth. Quickly coordinate e-business applications through
                revolutionary catalysts for change. Seamlessly underwhelm
                optimal testing.
              </p>
            </TestimonialCard2>
            <TestimonialCard2
              imageData={image2.nodes[0]}
              className=""
              personName="Wayne C. Corey"
              personInfo="Frontend Developer, ABC Tech2"
            >
              <p>
                We wouldn’t have such a great connection with different
                departments if it weren’t for Asana. I can interact with
                engineers directly and establish working relationships, and that
                collaboration has been extremely valuable.
              </p>
            </TestimonialCard2>
            <TestimonialCard2
              imageData={image3.nodes[0]}
              className=""
              personName="Karen J. Faller"
              personInfo="Product Designer"
            >
              <p>
                Proactively fabricate one-to-one materials via effective
                e-business. Completely synergize scalable e-commerce rather than
                high standards in e-services. Assertively iterate resource
                maximizing products.
              </p>
            </TestimonialCard2>
            <TestimonialCard2
              imageData={image4.nodes[0]}
              className=""
              personName="James G. Riding"
              personInfo="Designer"
            >
              <p>
                Dynamically target high-payoff intellectual capital for
                customized technologies. Objectively integrate emerging core
                competencies before process-centric communities. Dramatically
                evisculate.
              </p>
            </TestimonialCard2>
            <TestimonialCard2
              imageData={image2.nodes[0]}
              className=""
              personName="Wayne C. Corey"
              personInfo="Frontend Developer, ABC Tech2"
            >
              <p>
                Dynamically target high-payoff intellectual capital for
                customized technologies. Objectively integrate emerging core
                competencies before process-centric communities. Dramatically
                evisculate.
              </p>
            </TestimonialCard2>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Testimonial2
