import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TestimonialCard2 = ({
  children,
  className = "",
  personName = "Erin E. Behrens",
  personInfo = "Frontend Developer, ABC Tech",
  imageData,
}) => {
  const image = getImage(imageData)

  return (
    <div className={`keen-slider__slide ${className}`}>
      <div className="flex w-full h-full">
        <div className="grid h-full grid-cols-1 laptop:grid-cols-12">
          {/* left-part cards */}

          <div className=" laptop:col-span-5">
            <div className="">
              <GatsbyImage
                image={image}
                alt={personName}
                className="rounded-3xl"
              />
            </div>
          </div>

          {/* right-part */}
          <div
            className={`laptop:col-span-7 pt-[36px] laptop:pt-0 laptop:pl-[52px]`}
          >
            {/* texts */}
            <div className="flex flex-col items-start justify-center !h-full gap-6 laptop:gap-10">
              <h6 className="text-body-xl tablet:text-display-xs text-neutral-500">
                {children}
              </h6>
              <div className="flex flex-col gap-1">
                <p className="font-medium text-body-lg text-neutral-700">
                  {personName}
                </p>
                <p className="text-body-md text-neutral-500">{personInfo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TestimonialCard2
